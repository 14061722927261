import { createSlice } from '@reduxjs/toolkit';

import {
  fetchTaskInfo,
  fetchTaskInfoFleetLevelGeoRegionChart, fetchTaskInfoFleetLevelFindingChart,
  fetchPrecomputedRawTaskReports,
  fetchPrecomputedRawTaskReportsColumnSearch,
  fetchTaskInfoOperatorLevelReportReceivedYearly,
  fetchTaskInfoOperatorLevelFindingChart,
  fetchTaskInfoOperatorLevelFindingRateByMsn,
  fetchTaskReportOperatorCard,
  fetchTaskReportWorldWideFleetCard,
} from './taskreportAsyncThunks';
import {
  TASKS_SLICE, DEFAULT_OPERATOR_FR_BY_MSN_STATE, DEFAULT_FR_GEO_REGION_STATE, DEFAULT_OPERATOR_LEVEL_FC_STATE,
  DEFAULT_FLEET_LEVEL_FC_STATE,
  DEFAULT_FLEET_CARD_STATE,
  DEFAULT_ACTUAL_INTERVAL_STATE,
  DEFAULT_CUMMULATIVE_INTERVAL_STATE,
  DEFAULT_PERFORMANCE_STATE,
  DEFAULT_OPERATOR_CARD_STATE,
} from './constants';
import {
  fetchTaskInfoPending, fetchTaskInfoFulfilled, fetchTaskInfoRejected,
  clearRawTaskReportfilterGroupReducer, updateTaskReportPerformanceTuningReducer, clearTaskReportfilterGroupReducer, resetMetaDataReducer,
  fetchTaskInfoFleetLevelGeoRegionChartPending, fetchTaskInfoFleetLevelGeoRegionChartFulfilled, fetchTaskInfoFleetLevelGeoRegionChartRejected,
  fetchTaskInfoFleetLevelFindingChartPending, fetchTaskInfoFleetLevelFindingChartFulfilled, fetchTaskInfoFleetLevelFindingChartRejected,
  fetchTaskInfoOperatorLevelReportReceivedYearlyPending,
  fetchTaskInfoOperatorLevelReportReceivedYearlyFulfilled,
  fetchTaskInfoOperatorLevelReportReceivedYearlyRejected,
  fetchTaskInfoOperatorLevelFindingChartPending,
  fetchTaskInfoOperatorLevelFindingChartFulfilled,
  fetchTaskInfoOperatorLevelFindingChartRejected,
  fetchTaskInfoOperatorLevelFindingRateByMsnPending,
  fetchTaskInfoOperatorLevelFindingRateByMsnFulfilled,
  fetchTaskInfoOperatorLevelFindingRateByMsnRejected,
  precompActualIntervalByTypeInitReducer, precompActualIntervalByTypeFulfilledReducer, precompActualIntervalByTypeRejectedReducer,
  precompCumulativeIntervalByTypeInitReducer, precompCumulativeIntervalByTypeFulfilledReducer, precompCumulativeIntervalByTypeRejectedReducer,
  clearPrevfilterIdReducer,
  fetchPrecomputeRawTaskReportsPending,
  fetchPrecomputeRawTaskReportsFulfilled,
  fetchPrecomputeRawTaskReportsRejected,
  fetchPrecomputeRawTaskReportsColumnSearchFulfilled,
  updatePrecomputeRawTaskReportfilterReducer,
  updatePrecomputeRawTaskReportfilterGroupReducer,
  updatePrecomputeRawTaskReportfilterValueReducer,
  deletePrecomputeRawTaskReportfilterGroupReducer,
  updatePrecomputeTaskReportPerformanceTuningReducer,
  fetchTaskReportOperatorCardPending,
  fetchTaskReportOperatorCardFulfilled,
  fetchTaskReportOperatorCardRejected,
  fetchTaskReportWorldWideFleetCardPending,
  fetchTaskReportWorldWideCardFulfilled,
  fetchTaskReportWorldWideCardRejected,
  updateEnhanceStateReducer,
  clearPrecomputeRawTaskReportsReducer,
  clearTadaState,
} from './taskReportReducers';
import tadaExtraReducers from './taskReportExtraReducers';
import { getTaskReportByIntervalDefaultGraphData } from './taskReportUtils';

export const initialState: TaskReportState = {
  loading: false,
  error: '',
  status: 'idle',
  httpStatus: '',
  operator: '',
  performance: {
    ...DEFAULT_PERFORMANCE_STATE,
  },
  taskInfo: {
    mpd_task_reference: '',
    source_document_reference: '',
    source_document_type: '',
    mpd_task_description: '',
    mpd_task_code: '',
    mpd_100_threshold: '',
    mpd_100_interval: '',
    applicability_text: '',
    is_calculable: '',
  },
  taskReportAnalysisOperatorCard: {
    ...DEFAULT_OPERATOR_CARD_STATE,
  },
  findingRateByGeoRegion: {
    ...DEFAULT_FR_GEO_REGION_STATE,
  },
  fleetWideFindingsByFindingType: {
    ...DEFAULT_FLEET_LEVEL_FC_STATE,
  },
  taskInfoFleetCard: {
    ...DEFAULT_FLEET_CARD_STATE,
  },
  taskInfoOperatorLevelReportReceivedYearly: {
    loading: false,
    error: '',
    httpStatus: '',
    status: 'idle',
    operator_task_year: [],
    operator_task_year_count: [],
    operator_task_month: [],
  },
  taskInfoOperatorLevelFindingChart: {
    ...DEFAULT_OPERATOR_LEVEL_FC_STATE,
  },
  taskInfoOperatorLevelFindingRateByMsn: {
    ...DEFAULT_OPERATOR_FR_BY_MSN_STATE,
  },
  taskReportByActualInterval: {
    ...DEFAULT_ACTUAL_INTERVAL_STATE,
    data: getTaskReportByIntervalDefaultGraphData(['FC', 'FH', 'MONTH']),
  },
  taskReportByCummulativeInterval: {
    ...DEFAULT_CUMMULATIVE_INTERVAL_STATE,
    data: getTaskReportByIntervalDefaultGraphData(['CUMULATIVE_FC', 'CUMULATIVE_FH', 'AGE']),
  },
  precomputerawTaskReports: {
    loading: false,
    error: '',
    httpStatus: '',
    status: 'idle',
    data: [],
    totalCount: 0,
    searchPairData: {
      columnName: '',
      columnInput: '',
    },
    searchOptions: [],
    filterGroup: [],
  },
  tada: {
    isTadaOn: false,
    taskInfoOperatorLevelFindingRateByMsn: {
      ...DEFAULT_OPERATOR_FR_BY_MSN_STATE,
    },
    findingRateByGeoRegion: {
      ...DEFAULT_FR_GEO_REGION_STATE,
    },
    taskInfoOperatorLevelFindingChart: {
      ...DEFAULT_OPERATOR_LEVEL_FC_STATE,
    },
    fleetWideFindingsByFindingType: {
      ...DEFAULT_FLEET_LEVEL_FC_STATE,
    },
    taskInfoFleetCard: {
      ...DEFAULT_FLEET_CARD_STATE,
    },
    taskReportByActualInterval: {
      ...DEFAULT_ACTUAL_INTERVAL_STATE,
      data: getTaskReportByIntervalDefaultGraphData(['FC', 'FH', 'MONTH']),
    },
    taskReportByCummulativeInterval: {
      ...DEFAULT_CUMMULATIVE_INTERVAL_STATE,
      data: getTaskReportByIntervalDefaultGraphData(['CUMULATIVE_FC', 'CUMULATIVE_FH', 'AGE']),
    },
    performance: {
      ...DEFAULT_PERFORMANCE_STATE,
    },
    taskReportAnalysisOperatorCard: {
      ...DEFAULT_OPERATOR_CARD_STATE,
    },
  },
};

const taskSlice = createSlice({
  name: TASKS_SLICE,
  initialState,
  reducers: {
    // sync action creators
    clearRawTaskReportfilterGroup: clearRawTaskReportfilterGroupReducer,
    deletePrecomputeRawTaskReportfilterGroup: deletePrecomputeRawTaskReportfilterGroupReducer,
    updatePrecomputeRawTaskReportfilter: updatePrecomputeRawTaskReportfilterReducer,
    updatePrecomputeRawTaskReportfilterGroup: updatePrecomputeRawTaskReportfilterGroupReducer,
    updatePrecomputeRawTaskReportfilterValue: updatePrecomputeRawTaskReportfilterValueReducer,
    updateTaskReportPerformanceTuning: updateTaskReportPerformanceTuningReducer,
    updatePrecomputeTaskReportPerformanceTuning: updatePrecomputeTaskReportPerformanceTuningReducer,
    clearTaskReportState: clearTaskReportfilterGroupReducer,
    resetMetaData: resetMetaDataReducer,
    resetTadaToggle: clearTadaState,
    precompActualIntervalByTypeInit: precompActualIntervalByTypeInitReducer,
    precompActualIntervalByTypeFulfilled: precompActualIntervalByTypeFulfilledReducer,
    precompActualIntervalByTypeRejected: precompActualIntervalByTypeRejectedReducer,
    precompCumulativeIntervalByTypeInit: precompCumulativeIntervalByTypeInitReducer,
    precompCumulativeIntervalByTypeFulfilled: precompCumulativeIntervalByTypeFulfilledReducer,
    precompCumulativeIntervalByTypeRejected: precompCumulativeIntervalByTypeRejectedReducer,
    clearPrevfilterId: clearPrevfilterIdReducer,
    updateEnhancedAnalysisTadaToggle: updateEnhanceStateReducer,
    clearPrecomputeRawTask: clearPrecomputeRawTaskReportsReducer,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTaskInfo().pending, fetchTaskInfoPending);
    builder.addCase(fetchTaskInfo().fulfilled, fetchTaskInfoFulfilled);
    builder.addCase(fetchTaskInfo().rejected, fetchTaskInfoRejected);
    builder.addCase(fetchTaskReportOperatorCard().pending, fetchTaskReportOperatorCardPending);
    builder.addCase(fetchTaskReportOperatorCard().fulfilled, fetchTaskReportOperatorCardFulfilled);
    builder.addCase(fetchTaskReportOperatorCard().rejected, fetchTaskReportOperatorCardRejected);
    builder.addCase(fetchTaskReportWorldWideFleetCard().pending, fetchTaskReportWorldWideFleetCardPending);
    builder.addCase(fetchTaskReportWorldWideFleetCard().fulfilled, fetchTaskReportWorldWideCardFulfilled);
    builder.addCase(fetchTaskReportWorldWideFleetCard().rejected, fetchTaskReportWorldWideCardRejected);
    builder.addCase(fetchTaskInfoFleetLevelGeoRegionChart().pending, fetchTaskInfoFleetLevelGeoRegionChartPending);
    builder.addCase(fetchTaskInfoFleetLevelGeoRegionChart().fulfilled, fetchTaskInfoFleetLevelGeoRegionChartFulfilled);
    builder.addCase(fetchTaskInfoFleetLevelGeoRegionChart().rejected, fetchTaskInfoFleetLevelGeoRegionChartRejected);
    builder.addCase(fetchTaskInfoFleetLevelFindingChart().pending, fetchTaskInfoFleetLevelFindingChartPending);
    builder.addCase(fetchTaskInfoFleetLevelFindingChart().fulfilled, fetchTaskInfoFleetLevelFindingChartFulfilled);
    builder.addCase(fetchTaskInfoFleetLevelFindingChart().rejected, fetchTaskInfoFleetLevelFindingChartRejected);
    builder.addCase(fetchPrecomputedRawTaskReports().pending, fetchPrecomputeRawTaskReportsPending);
    builder.addCase(fetchPrecomputedRawTaskReports().fulfilled, fetchPrecomputeRawTaskReportsFulfilled);
    builder.addCase(fetchPrecomputedRawTaskReports().rejected, fetchPrecomputeRawTaskReportsRejected);
    builder.addCase(fetchPrecomputedRawTaskReportsColumnSearch().fulfilled, fetchPrecomputeRawTaskReportsColumnSearchFulfilled);
    builder.addCase(fetchTaskInfoOperatorLevelReportReceivedYearly().pending, fetchTaskInfoOperatorLevelReportReceivedYearlyPending);
    builder.addCase(fetchTaskInfoOperatorLevelReportReceivedYearly().fulfilled, fetchTaskInfoOperatorLevelReportReceivedYearlyFulfilled);
    builder.addCase(fetchTaskInfoOperatorLevelReportReceivedYearly().rejected, fetchTaskInfoOperatorLevelReportReceivedYearlyRejected);
    builder.addCase(fetchTaskInfoOperatorLevelFindingChart().pending, fetchTaskInfoOperatorLevelFindingChartPending);
    builder.addCase(fetchTaskInfoOperatorLevelFindingChart().fulfilled, fetchTaskInfoOperatorLevelFindingChartFulfilled);
    builder.addCase(fetchTaskInfoOperatorLevelFindingChart().rejected, fetchTaskInfoOperatorLevelFindingChartRejected);
    builder.addCase(fetchTaskInfoOperatorLevelFindingRateByMsn().pending, fetchTaskInfoOperatorLevelFindingRateByMsnPending);
    builder.addCase(fetchTaskInfoOperatorLevelFindingRateByMsn().fulfilled, fetchTaskInfoOperatorLevelFindingRateByMsnFulfilled);
    builder.addCase(fetchTaskInfoOperatorLevelFindingRateByMsn().rejected, fetchTaskInfoOperatorLevelFindingRateByMsnRejected);
    tadaExtraReducers(builder);
  },
});

export const {
  clearRawTaskReportfilterGroup,
  deletePrecomputeRawTaskReportfilterGroup,
  updatePrecomputeRawTaskReportfilter,
  updatePrecomputeRawTaskReportfilterGroup,
  updatePrecomputeRawTaskReportfilterValue,
  updateTaskReportPerformanceTuning,
  clearTaskReportState,
  resetMetaData,
  resetTadaToggle,
  precompActualIntervalByTypeInit,
  precompActualIntervalByTypeFulfilled,
  precompActualIntervalByTypeRejected,
  precompCumulativeIntervalByTypeInit,
  precompCumulativeIntervalByTypeFulfilled,
  precompCumulativeIntervalByTypeRejected,
  clearPrevfilterId,
  updatePrecomputeTaskReportPerformanceTuning,
  updateEnhancedAnalysisTadaToggle,
  clearPrecomputeRawTask,
} = taskSlice.actions;
export default taskSlice;
