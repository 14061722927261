import React, { useCallback, useEffect, useState } from 'react';
import {
  Col, Pagination, Row, Spinner,
} from '@airbus/components-react';
import MpdRepositoryAnalysisCard from '../../MpdRepositoryAnalysisCard/MpdRepositoryAnalysisCard';
import './BuildListContainer.scss';
import { useAppDispatch, useAppSelector } from '../../../store/hooksTypes';
import {
  PARepositoryState,
} from '../../../models/programAnalysisRepository/PARepositoryTypes';
import { RootState } from '../../../store/store';
import { fetchPARepositoryData } from '../../../models/programAnalysisRepository/PARepositoryAsyncThunk';
import {
  UPDATE_TIME, ID, STATUS, TITLE,
  CREATION_TIME,
  IMPACTED_BY_ANNEX12,
} from '../../../models/programAnalysisRepository/constants';
import { secureLS } from '../../../utils/localStorageUtil/lsHelper';
import MpdEmptyDataComponent from '../../Shared/MpdEmptyDataComponent/MpdEmptyDataComponent';
import { clearPARepoState } from '../../../models/programAnalysisRepository/PARepositorySlice';

const BuildListContainer = () => {
  const dispatch = useAppDispatch();
  const operator = secureLS.get('operator');
  const acProgram = secureLS.get('ac_program');
  const repositoryDataObject: PARepositoryState = useAppSelector((state: RootState) => state.PARepository);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [pollingCount, setPollingCount] = useState(0);
  const rowsPerPage = 10;
  const subTitle = 'You can create analysis from Program Analysis';
  const title = 'Saved results not available';
  const iconName = 'AssignmentLateOutline';
  const [dataDisplay, setDataDisplay] = useState<JSX.Element[] | undefined>(undefined);

  useEffect(() => {
    // onmount clear the existing store values for PA repository
    dispatch(clearPARepoState());
    dispatch(fetchPARepositoryData(operator, acProgram)());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (repositoryDataObject.rebulidLoading === 'done') {
      dispatch(fetchPARepositoryData(operator, acProgram)());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, repositoryDataObject.rebulidLoading]);
  const dataCompute = useCallback(() => {
    if (repositoryDataObject.loading) {
      return undefined;
    }
    const sortByUpdateTime = [...repositoryDataObject.repositoryData].sort((item1, item2) => {
      return item1[UPDATE_TIME] >= item2[UPDATE_TIME] ? -1 : 1;
    });
    return sortByUpdateTime.map((item) => (
      <MpdRepositoryAnalysisCard
        buildName={item[TITLE]}
        createdOn={item[CREATION_TIME]}
        updatedOn={item[UPDATE_TIME]}
        requestStatus={item[STATUS]}
        id={item[ID]}
        key={item[ID]}
        pollingCount={pollingCount}
        setPollingCount={setPollingCount}
        isAnnex13Impacted={item[IMPACTED_BY_ANNEX12]}
      />
    ));
  }, [pollingCount, repositoryDataObject]);

  useEffect(() => {
    const sliceDataCompute = dataCompute()?.slice((page * rowsPerPage) - 10, (page * rowsPerPage));
    setDataDisplay(sliceDataCompute);
  }, [page, rowsPerPage, dataCompute]);

  useEffect(() => {
    setPage(1);
    setTotalCount(repositoryDataObject?.repositoryData?.length);
  }, [repositoryDataObject.repositoryData]);

  const getPagination = () => {
    if (totalCount) {
      return (
        <Pagination
          legend={`Showing ${page * 10 - 9}-${Math.min(page * rowsPerPage, totalCount)} of ${totalCount} results`}
          page={page}
          count={totalCount}
          rowsPerPage={rowsPerPage}
          onPageChange={(event, pageSel) => setPage(pageSel)}
        />
      );
    }
    return <div />;
  };

  return (
    <Col className="build-content-cls">
      <Row className="repository-header-analysis-cls">
        <p className="repository-search-title">Program Analysis Results</p>
      </Row>
      <div className="build-list-container" style={repositoryDataObject.repositoryData.length !== 0 && !repositoryDataObject.loading ? { overflowY: 'scroll' } : {}}>
        {repositoryDataObject.loading && (
          <div className="spinner-placing">
            <Spinner />
          </div>
        )}
        {!repositoryDataObject.loading && repositoryDataObject.repositoryData.length === 0 && (
          <div className="no-data-component-cls no-data warning spinner-placing">
            <MpdEmptyDataComponent iconName={iconName} title={title} subTitle={subTitle} />
          </div>
        )}
        {dataDisplay}
      </div>
      <div className="repository-paginatation">
        {getPagination()}
      </div>
    </Col>
  );
};

export default BuildListContainer;
