import { store } from '../../store/store';
import { MODULE_MAPPER } from '../commonUtils/PermissionMapper';

// Check if the required SAS permission(s) is allowed for the user.
const checkSasPermission = (requiredSasPermissions: string[]) => {
  const allowedPermissions = store?.getState().userPermissions.sasPermissions;

  // Check if every required permission is allowed for the user.
  const result = requiredSasPermissions.every(
    (requiredPermission) => (
      // Check if the required permission is in the user's allowed permissions.
      allowedPermissions.some((permission) => permission.code === requiredPermission)),
  );

  return result;
};

// Check if the user has access to required feature(s).
const checkFeaturePermission = (requiredFeaturePermissions: string[]) => {
  const allowedFeatures = store?.getState().userPermissions.featurePermissions;

  // Check if every required feature is allowed for the user.
  const result = requiredFeaturePermissions.every(
    (requiredFeature) => (
      // Check if the required feature is in the user's allowed features.
      allowedFeatures.some((feature) => feature.featureCode === requiredFeature)),
  );

  return result;
};

// Switch between 2 features as per the required permissions.
// Returns allowedFeature when all the required permissions are present else it returns alternateFeature.
// allowedFeature and alternateFeature can be a component or a function.
const userPermissionFeatureSwitch = <Type>(
  requiredFeaturePermissions: string[],
  allowedFeature: Type,
  alternateFeature?: Type,
) => {
  if (checkFeaturePermission(requiredFeaturePermissions)) {
    return allowedFeature;
  }

  return alternateFeature;
};

/**
 * checks user module permission for a permission type ( skywise, premium, etc.. )
 * @param permissionFor module defined in PermissionMapper util eg: PROGRAM_ANALYSIS_MODULE
 * @param permissionType permission type defined in PermissionMapper util eg: PREMIUM_PERMISSION
 * @returns boolean
 */
const checkPermissionByType = (permissionFor: string, permissionType: string) => {
  // get all the user permissions
  const allUserPermissions = store?.getState().userPermissions.sasPermissions.map((item) => item.code);
  // select only the permission related to the module eg: SMAC_PA_MPE_RW_ABUS
  const userPermission = allUserPermissions.filter((item: string) => item.indexOf(MODULE_MAPPER[permissionFor]));
  return userPermission?.[0]?.indexOf(permissionType) > 0;
};

export {
  checkSasPermission, checkFeaturePermission, userPermissionFeatureSwitch, checkPermissionByType,
};
