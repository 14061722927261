import React, { useEffect, useRef, useState } from 'react';
import {
  Chip, MenuButton, IconButton, Input,
} from '@airbus/components-react';
import './MpdRepositoryAnalysisCard.scss';
import {
  Refresh, Delete, Check, Edit, Settings,
} from '@airbus/icons/react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../store/hooksTypes';
import {
  deleteBuildData,
  fetchPARepositoryData,
  retryBuildData,
  updateTitle,
} from '../../models/programAnalysisRepository/PARepositoryAsyncThunk';
import { POSSIBLE_BUILD_STATUS } from '../../models/programAnalysis/constants';
import DialogComponent from '../Shared/DialogComponent/DialogComponent';
import {
  MPD_DELETE_PRIMARY_BUTTON_NAME, MPD_DELETE_SECONDARY_BUTTON_NAME, ANALYSIS_DELETE_TITLE, ANALYSIS_DELETE_MESSAGE,
} from '../Shared/DialogComponent/Constants';
import { createToast } from '../../models/ToastModel/toastSlice';
import { clearBuild } from '../../models/programAnalysis/programAnalysisSlice';
import { RebuildPollingInterval } from '../TaskReportAnalysis/constants';
import { secureLS } from '../../utils/localStorageUtil/lsHelper';
import { formatRepositoryDate } from './MpdRepositoryUtil';
import { addBanner, clearBannerLocation } from '../../models/bannerModel/bannerSlice';
import { bannerLocationMap, REPO_FILE_DELETE_FAILED, REPO_FILE_DELETE_SUCCESS } from '../../models/bannerModel/constants';

type mpdRepositoryAnalysisProps = {
  buildName: string;
  createdOn?: string;
  updatedOn?: string;
  requestStatus: string;
  id: string;
  // polling the Program Analysis results if any rebuild happens
  pollingCount?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setPollingCount?: any;
  isAnnex13Impacted ?: boolean
};

const MpdRepositoryAnalysisCard = (props: mpdRepositoryAnalysisProps) => {
  const operator = secureLS.get('operator');
  const acProgram = secureLS.get('ac_program');
  const {
    buildName, createdOn, updatedOn, requestStatus, id, pollingCount, setPollingCount, isAnnex13Impacted,
  } = props;
  const dispatch = useAppDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [cardTitle, setCardTitle] = useState(buildName);
  const [isRetryDisabled, setIsRetryDisabled] = useState(false);
  const [isDeleteDisabled, setIsDeleteDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const pollingIntervalRef = useRef<any>();

  const navigate = useNavigate();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const editTitle = () => {
    setIsEditing(false);
    if (cardTitle !== buildName) {
      dispatch(updateTitle(id, cardTitle)({ id, cardTitle }));
    }
  };

  const goToResults = () => {
    navigate(`/program-analysis/${id}`);
  };

  const deleteBuild = async () => {
    const clearAndAddNewBanner = (banner: BannerType) => {
      dispatch(clearBannerLocation(bannerLocationMap.REPOSITORY_MODULE));
      dispatch(clearBannerLocation(bannerLocationMap.GLOBAL));
      dispatch(addBanner({
        ...banner,
        bannerMessage: banner.bannerMessage.replace('`<FILENAME>`', `"${cardTitle}"`),
      }));
    };

    try {
      setIsDeleteDisabled(true);
      dispatch(clearBuild());
      await dispatch(deleteBuildData(id)({ id })).unwrap();
      clearAndAddNewBanner(REPO_FILE_DELETE_SUCCESS);
    } catch (error) {
      clearAndAddNewBanner(REPO_FILE_DELETE_FAILED);
    }
  };

  const retryBuild = () => {
    setPollingCount(0);
    dispatch(retryBuildData(id)({ id }));
    setIsRetryDisabled(true);
  };

  useEffect(() => {
    // polling fetchPARepositoryData Api
    // if all mpdcards status is not in [INIT or IN_PROGRESS or UPDATE_INIT] status polling stops else it polls maximum 10 times
    if (([POSSIBLE_BUILD_STATUS.INIT, POSSIBLE_BUILD_STATUS.IN_PROGRESS, POSSIBLE_BUILD_STATUS.UPDATE_INIT].includes(requestStatus)) && (pollingCount != null && pollingCount < 10)) {
      pollingIntervalRef.current = setInterval(() => {
        // eslint-disable-next-line no-unused-expressions
        (pollingCount != null) && setPollingCount(pollingCount + 1);
        dispatch(fetchPARepositoryData(operator, acProgram)());
      }, RebuildPollingInterval);
    }
    return () => {
      clearInterval(pollingIntervalRef.current);
    };
  }, [requestStatus, acProgram, operator, dispatch, pollingCount, setPollingCount]);

  const analysisCardMenuBtnHandler = () => {
    if (requestStatus === POSSIBLE_BUILD_STATUS.FAILED) {
      return (
        <MenuButton
          aria-label="retryBuild"
          variant="ghost"
          size="xsmall"
          className="action-btn-cls"
          icon={<Refresh />}
          onClick={retryBuild}
          disabled={isRetryDisabled}
        >
          Retry Submit
        </MenuButton>
      );
    }
    if (requestStatus === POSSIBLE_BUILD_STATUS.READY) {
      return (
        <div className="button-newArc">
          <MenuButton
            aria-label="reBuild"
            variant="ghost"
            className="rebuild"
            size="xsmall"
            icon={<Settings />}
            onClick={() => {
              retryBuild();
              dispatch(
                createToast({
                  id,
                  cssClass: 'repo-tost-cls',
                  variant: undefined,
                  title: 'Re-build in progress',
                  content: [buildName],
                }),
              );
            }}
            disabled={isRetryDisabled}
          >
            Re-build
          </MenuButton>
          <MenuButton
            aria-label="goToResult"
            variant="primary"
            className="action-btn-cls"
            onClick={goToResults}
            size="xsmall"
          >
            View results
          </MenuButton>
        </div>
      );
    }
    return (
      <MenuButton
        aria-label="goToResult"
        variant="primary"
        className="action-btn-cls"
        size="xsmall"
        onClick={goToResults}
      >
        View results
      </MenuButton>
    );
  };

  const deleteMessage = (
    <p>
      {ANALYSIS_DELETE_MESSAGE}
      <br />
      <b>{buildName}</b>
    </p>
  );

  const renderNewArchitectureMpdCard = () => {
    return (
      <div className="mpd-repository-analysis-card">
        <div className="left-panel-newArc">
          <div>
            <div className="title">
              {isEditing ? (
                <>
                  <Input
                    value={cardTitle}
                    size="small"
                    aria-label="name-input"
                    onChange={(_, val) => setCardTitle(val)}
                    // maximum 150 characters allowed for title
                    maxLength={150}
                  />
                  <span>
                    <IconButton
                      variant="ghost"
                      aria-label="validate"
                      size="small"
                      onClick={editTitle}
                    >
                      <Check />
                    </IconButton>
                  </span>
                </>
              ) : (
                <>
                  <p>
                    <b>{cardTitle}</b>
                  </p>
                  <span>
                    <IconButton
                      variant="ghost"
                      aria-label="edit"
                      size="small"
                      onClick={() => setIsEditing(true)}
                    >
                      <Edit />
                    </IconButton>
                  </span>
                </>
              )}
            </div>
            <div className="details">
              <div className="card_content">
                <p className="status">
                  {requestStatus === POSSIBLE_BUILD_STATUS.READY && !isAnnex13Impacted && (
                    <Chip variant="success" className="status-chip">
                      <b>Available</b>
                    </Chip>
                  )}
                  {requestStatus === POSSIBLE_BUILD_STATUS.READY && isAnnex13Impacted && (
                    <Chip variant="error" className="status-chip">
                      <b>Impacted by Annex-13</b>
                    </Chip>
                  )}
                  {[
                    POSSIBLE_BUILD_STATUS.IN_PROGRESS,
                    POSSIBLE_BUILD_STATUS.INIT,
                    POSSIBLE_BUILD_STATUS.UPDATE_INIT,
                  ].includes(requestStatus) && (
                    <Chip variant="warning" className="status-chip">
                      <b>In progress</b>
                    </Chip>
                  )}
                  {requestStatus === POSSIBLE_BUILD_STATUS.FAILED && (
                    <Chip variant="error" className="status-chip">
                      <b>Failed</b>
                    </Chip>
                  )}
                </p>
                <div className="creation-updation-time">
                  <span>Created on </span>
                  {createdOn ? `${formatRepositoryDate(createdOn)}` : ''}
                </div>
                { createdOn !== updatedOn && (
                <div className="creation-updation-time">
                  <span>Updated on </span>
                  {updatedOn ? `${formatRepositoryDate(updatedOn)}` : ''}
                </div>
                )}
              </div>
            </div>
          </div>
          <div className="right-panel-newArc">
            { !isAnnex13Impacted && analysisCardMenuBtnHandler()}
            <IconButton
              aria-label="deleteBuild"
              className="delete-btn-cls"
              size="xsmall"
              onClick={handleOpen}
              disabled={isDeleteDisabled || [
                POSSIBLE_BUILD_STATUS.IN_PROGRESS,
                POSSIBLE_BUILD_STATUS.INIT,
                POSSIBLE_BUILD_STATUS.UPDATE_INIT,
              ].includes(requestStatus)}
            >
              <Delete />
            </IconButton>
          </div>

          <div />
        </div>
        <DialogComponent
          message={deleteMessage}
          dialogTitle={ANALYSIS_DELETE_TITLE}
          primmaryButtonName={MPD_DELETE_PRIMARY_BUTTON_NAME}
          secondaryButtonName={MPD_DELETE_SECONDARY_BUTTON_NAME}
          open={open}
          onClose={handleClose}
          onSubmit={deleteBuild}
        />
      </div>
    );
  };

  return (
    <div>
      {renderNewArchitectureMpdCard()}
    </div>
  );
};
export default MpdRepositoryAnalysisCard;

MpdRepositoryAnalysisCard.defaultProps = {
  createdOn: '',
  updatedOn: '',
  pollingCount: 0,
  isAnnex13Impacted: false,
  setPollingCount: () => {},
};
